<div class="collapse" id="navbarToggleExternalContent">
    <div class="bg-dark p-4">
        <div class="container">
            <div class="row">
                <div class="col">
                    <a [routerLink]="['/about']">
                        <figure class="figure">
                            <figcaption class="figure-caption">Professional Products</figcaption>
                            <img src="../../../assets/images/professional_sliding-260x135.png"
                                class="figure-img img-fluid rounded" alt="...">
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="navbar sticky-top navbar-dark cc-bg-grey top-navbar">
    <div class="container-fluid top-nav-container">
        <button id="toggle-element" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation" (click)="ToggleIcon($event)">
            <svg id="toggle-element-icon" class="bi bs-icon" fill="currentColor">
                <use xlink:href="bootstrap-icons.svg#chevron-down" />
            </svg>
        </button>
    </div>
</nav>