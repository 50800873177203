import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    

  }

  ToggleIcon(event: any) {
    
    var toggleElement = document.getElementById("toggle-element");

    var expandedAttr = toggleElement.getAttribute("aria-expanded");

    if (expandedAttr == "true") {
      document.getElementById("toggle-element-icon").innerHTML = "<use xlink:href='bootstrap-icons.svg#chevron-up' />";
    } else {
      document.getElementById("toggle-element-icon").innerHTML = "<use xlink:href='bootstrap-icons.svg#chevron-down' />";
    }

  }

}
