import { enableProdMode, Component } from '@angular/core';
import { Location } from '@angular/common';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'co-operative-computing';

  isLanding: boolean = false;

  constructor(private location: Location) {

    if (this.location.path() === "/landing") {

      this.isLanding = false;

    } else {

      this.isLanding = true;

    }

  }

}
