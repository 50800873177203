<div class="container-fluid about-header-alt-image">
    <div class="row">
        <div class="col text-center">
            <h1 class="mt-4">Technical Consulting</h1>
        </div>
    </div>
</div>

<div class="container">
    <div class="row pt-5 pb-4">
        <div class="col text-center">

            <small>
                Your company’s technological practices should support your business, not constrain it.
            </small>

            <br>
            <br>

            <small>
                We focus on the strategic needs of your business to determine the capabilities needed to support your
                unique IT requirements.
            </small>

            <br>
            <br>

            <small>
                Co-Operative Computing helps you address technology-related decisions to ensure your operating models
                are agile and effective, saving you time and optimising your business.
            </small>
        </div>
    </div>

    <div class="row pt-5 pb-4">
        <div class="col text-center">
            <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                <use xlink:href="bootstrap-icons.svg#clock-history" />
            </svg>

            <h5 class="mt-5">Availability when you need it</h5>
        </div>

        <div class="col text-center">
            <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                <use xlink:href="bootstrap-icons.svg#graph-up" />
            </svg>

            <h5 class="mt-5">Technical expertise and experienced consultants</h5>
        </div>

        <div class="col text-center">
            <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                <use xlink:href="bootstrap-icons.svg#trophy-fill" />
            </svg>

            <h5 class="mt-5">Proven, cost-efficient solutions</h5>
        </div>

        <div class="col text-center">
            <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                <use xlink:href="bootstrap-icons.svg#check2" />
            </svg>

            <h5 class="mt-5">Integrated solutions that suit your existing environment</h5>
        </div>
    </div>
</div>

<div class="container-fluid about-header-alt-image">
    <div class="row">
        <div class="col text-center">
            <h1 class="mt-4">Training</h1>
        </div>
    </div>
</div>

<div class="container">
    <div class="row pt-5 pb-4">
        <div class="col text-right">
            <img src="../../../assets/images/imanage_workpng.png">
        </div>

        <div class="col">
            <h5>iManage Work</h5>
            <br>
            <small>Co-Operative Computing is proud to be the only iManage-certified training partner in Africa. This
                certification allows us to offer the following courses:</small>
            <br>
            <br>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">iManage 10 Application Essentials</li>
                <li class="list-group-item">iManage Concepts and Terminology</li>
                <li class="list-group-item">Workspace Management</li>
                <li class="list-group-item">Workspace Design and Creation</li>
                <li class="list-group-item">Saving and Copying Documents</li>
                <li class="list-group-item">Retrieving Documents</li>
                <li class="list-group-item">Advanced Searching</li>
                <li class="list-group-item">Email Management</li>
                <li class="list-group-item">Document Menu Commands</li>
                <li class="list-group-item">User-configurable Options</li>
                <li class="list-group-item">Integrated Applications</li>
                <li class="list-group-item">Off-Site</li>
                <li class="list-group-item">iManage CC</li>
                <li class="list-group-item">iManage Work Certified Engineer (iCSE)</li>
            </ul>
        </div>
    </div>
</div>