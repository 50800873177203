<nav class="navbar navbar-expand-lg sticky-top navbar-dark cc-bg-grey">
    <!-- Navbar content -->
    <div class="container-fluid">
        <a class="navbar-brand mr-4" [routerLink]="['/about']">
            <img src="../../../assets/images/logo.png" class="logo-brand" alt="Co-Operative Computing" />
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                
            </ul>

            <ul class="nav justify-content-end">
                <li class="nav-item mr-3 ml-3">
                    <a class="nav-link" [routerLink]="['/about']" routerLinkActive="active">
                        ABOUT
                    </a>
                </li>

                <li class="nav-item mr-3 ml-3" hidden>
                    <a class="nav-link" [routerLink]="['/products']" routerLinkActive="active">
                        PRODUCTS
                    </a>
                </li>

                <li class="nav-item mr-3 ml-3">
                    <a class="nav-link" [routerLink]="['/prof-services']" routerLinkActive="active">
                        PROFESSIONAL SERVICES
                    </a>
                </li>

                <li class="nav-item mr-3 ml-3 " hidden>
                    <a class="nav-link" [routerLink]="['/contact']" routerLinkActive="active">
                        CONTACT
                    </a>
                </li>

                <li class="nav-item mr-3 ml-3" hidden>
                    <a class="nav-link" [routerLink]="['/clients']" routerLinkActive="active">
                        OUR CLIENTS
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>