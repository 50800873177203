<div class="container">
    <div class="row mt-4">
        <div class="col text-center">

            <img src="../../../assets/images/logo.png" alt="Co-Operative Computing" class="c-logo" />

        </div>
    </div>

    <div class="row mt-4">
        <div class="col text-center">

            <hr>

            <h3 class="c-heading3">Click the link to read more about the Corona Virus <a href="http://www.sacoronavirus.co.za/" class="c-heading3">http://www.sacoronavirus.co.za/</a></h3>

            <hr>

        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="row">
        <div class="col c-col-no-padding landing-bk-pro">
            <div class="landing-image-col landing-image-pro">
            </div>
        </div>

        <div class="col c-col-no-padding landing-bk-hs">
            <div class="landing-image-col landing-image-hs">
            </div>
        </div>
    </div>
</div>