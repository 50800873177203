<div class="container-fluid">
    <div class="row mt-4">
        <div class="col text-center">

            <img src="../../../assets/images/logo.png" alt="Co-Operative Computing" class="c-logo" />

        </div>
    </div>

    <div class="row mt-4">
        <div class="col text-center">

            <hr>

            <h3 class="c-heading3">Click the link to read more about the Corona Virus <a
                    href="http://www.sacoronavirus.co.za/" class="c-heading3">http://www.sacoronavirus.co.za/</a></h3>

            <hr>

        </div>
    </div>


</div>

<div class="container mb-4">
    <div class="row pt-5 pb-4">
        <div class="col text-center">
            <h2>Time is the soul of a business, and you need time to grow your business.</h2>

            <br>

            <small>
                Co-Operative Computing optimises the time that you have, so you can use it on things that matter more.
                Our specialist IT services offers comprehensive, tailor-made software and support solutions.
            </small>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col about-icon-org">
            <div class="about-icon-col about-icon1">
            </div>
        </div>

        <div class="col about-icon-org">
            <div class="about-icon-col about-icon2">
            </div>
        </div>

        <div class="col about-icon-org">
            <div class="about-icon-col about-icon3">
            </div>
        </div>

        <div class="col about-icon-org">
            <div class="about-icon-col about-icon4">
            </div>
        </div>
    </div>

    <div class="row pt-5 pb-5">
        <div class="col text-center">
            <h6>
                Specialised Practice
                <br> Management Applications
            </h6>
        </div>

        <div class="col text-center">
            <h6>
                Specialist Document
                <br> Management Systems
            </h6>
        </div>

        <div class="col text-center">
            <h6>
                Technical
                <br> Consulting
            </h6>
        </div>

        <div class="col text-center">
            <h6>
                Personalised Training
                <br> and Support
            </h6>
        </div>
    </div>

    <div class="row pt-5">
        <div class="col text-center">
            <small>
                By optimising your administration processes and managing your IT operation, you can concentrate on
                expanding your legal- or professional business, while Co-Operative Computing takes care of the rest.
            </small>
        </div>
    </div>
</div>

<div class="container-fluid about-header-alt-image">
    <div class="row">
        <div class="col text-center">
            <h1 class="mt-4">History of Co-Operative Computing</h1>
        </div>
    </div>
</div>

<div class="container">
    <div class="row pt-5 pb-4">
        <div class="col text-center">

            <small>
                Co-Operative Computing East Africa is a subsidiary of Co-Operative Computing (Pty) Ltd based in Nairobi,
                Kenya.
            </small>

            <br>
            <br>

            <small>
                Utilising over 25 years of experience, our team of experts offer dedicated and first-rate solutions to
                meet your requirements.
            </small>

            <br>
            <br>

            <small>
                We pride ourselves on maintaining a reputation for efficiency and high-quality service, and ensure that
                your unique business needs are held to the highest standard.
            </small>
        </div>
    </div>

    <div class="row pt-5 pb-4">
        <div class="col text-center">
            <div class="row">
                <div class="col-3">
                    <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#check" />
                    </svg>
                </div>
                <div class="col mt-4">
                    Improving clients’ efficiencies and productivity through business process consulting
                </div>
            </div>
        </div>

        <div class="col text-center">
            <div class="row">
                <div class="col-3">
                    <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#check" />
                    </svg>
                </div>
                <div class="col mt-4">
                    Using innovative technology in practice management, document management and workflow solutions
                </div>
            </div>
        </div>
    </div>

    <div class="row pt-5 pb-4">
        <div class="col text-center">
            <div class="row">
                <div class="col-3">
                    <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#check" />
                    </svg>
                </div>
                <div class="col mt-4">
                    Servicing client companies who manage their IT requirements in-house
                </div>
            </div>
        </div>

        <div class="col text-center">
            <div class="row">
                <div class="col-3">
                    <svg class="bi tick-icon" width="100" height="100" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#check" />
                    </svg>
                </div>
                <div class="col mt-4">
                    Servicing clients who require cost-efficient solutions by out-sourcing IT management services
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid about-header-alt-image">
    <div class="row">
        <div class="col text-center">
            <h1 class="mt-4">Meet Our Leaders</h1>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row">
        <div class="col text-center">
            <h3>Carlo Pagani</h3>
            <br>
            <img src="../../../assets/images/carlo.jpg" class="img-thumbnail img-rounded" alt="...">
            <br>
            <h4>Managing Director</h4>
            <br>
            <small>Carlo founded Co-operative Computing in 1991, and believes that support is a key component to
                providing service at great value. Having started his programming career in 1979, Carlo lends a wealth of
                experience to the company that spans over three decades, and since regards his work as a hobby. He’s
                also a ‘70s music enthusiast and a self-professed bookworm.</small>
            <br>
            <br>
            <figure>
                <blockquote class="blockquote">
                    <p>“There are two kinds of companies: those that work to try and to charge more, and those that work
                        to try and charge less. We’ll be the second.”</p>
                </blockquote>
                <figcaption class="blockquote-footer">
                    Jeff Bezos
                </figcaption>
            </figure>
        </div>

        <div class="col text-center">
            <h3>Matteo Pagani</h3>
            <br>
            <img src="../../../assets/images/matteo.jpg" class="img-thumbnail img-rounded" alt="...">
            <br>
            <h4>Chief Operating Officer</h4>
            <br>
            <small>Matteo joined Co-Operative Computing in 2006 as a technician, and brings a pioneering vision and
                strategy to the business. Having attended the Gordons Institute of Business Science, he is also a driven
                and motivated leader.
                As a representative of South Africa in Canopy Piloting, Matteo aspires to take Co-Operative Computing to
                equally swooping heights.</small>
            <br>
            <br>
            <figure>
                <blockquote class="blockquote">
                    <p>`All men dream: but not equally. Those who dream by night I the dusty recesses of their minds
                        wake in the day to find that it was vanity. But the dreamers of the day are dangerous men, for
                        they may act their dreams with open eyes to make it possible… ” </p>
                </blockquote>
                <figcaption class="blockquote-footer">
                    T.E. Lawrence
                </figcaption>
            </figure>
        </div>

        <div class="col text-center">
            <h3>Martin Ondu</h3>
            <br>
            <img src="../../../assets/images/martin.jpg" class="img-thumbnail img-rounded" alt="...">
            <br>
            <h4>Managing Director</h4>
            <br>
            <small>Martin joins Co-Operative Computing East Africa as the local MD, and brings to Co-Operative Computing
                East Africa local East African knowledge and pride.</small>
        </div>
    </div>
</div>