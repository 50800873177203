import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './navigation/landing/landing.component';
import { TopNavigationComponent } from './navigation/top-navigation/top-navigation.component';
import { BottomNavigationComponent } from './navigation/bottom-navigation/bottom-navigation.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { MainNavigationComponent } from './navigation/main-navigation/main-navigation.component';
import { AboutComponent } from './prof-products/about/about.component';
import { ProductsComponent } from './prof-products/products/products.component';
import { ProfServicesComponent } from './prof-products/prof-services/prof-services.component';
import { ContactComponent } from './prof-products/contact/contact.component';
import { ClientsComponent } from './prof-products/clients/clients.component';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    TopNavigationComponent,
    BottomNavigationComponent,
    FooterComponent,
    MainNavigationComponent,
    AboutComponent,
    ProductsComponent,
    ProfServicesComponent,
    ContactComponent,
    ClientsComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
