<div class="container-fluid cc-bg-grey">
    <div class="row pt-4 pb-4">
        <div class="col text-center">

            <img src="../../../assets/images/logo.png" alt="Co-Operative Computing" class="c-footer-logo" />

            <br><br>

            <div class="text-left ml-5">
                <small class="c-white-text">
                    <svg class="bi" width="16" height="16" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#geo-alt-fill" />
                    </svg>
                    1st Ngong Ave
                    <br> ACK Garden Annex
                    <br> 4th Floor
                    <br> Nairobi
                </small>

                <br>

                <small class="c-white-text">
                    <svg class="bi" width="16" height="16" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#envelope" />
                    </svg>
                    P.O.Box 1249
                    <br> Randburg 2125
                </small>

                <br>

                <small class="c-white-text">
                    <svg class="bi" width="16" height="16" fill="currentColor">
                        <use xlink:href="bootstrap-icons.svg#telephone-fill" />
                    </svg>
                    Tel: +27 11 886 9870
                </small>
            </div>

        </div>

        <div class="col" hidden>

            <h4 class="c-white-text">Legal</h4>

            <ul class="list-group">
                <li class="list-group-item"><a href="">Privacy Policy</a></li>
                <li class="list-group-item"><a href="">Terms & Conditions</a></li>
            </ul>

        </div>

        <div class="col" hidden>

            <h4 class="c-white-text">Professional Products</h4>

            <ul class="list-group">
                <li class="list-group-item"><a href="">About Co-Operative Computing</a></li>
                <li class="list-group-item"><a href="">Products</a></li>
                <li class="list-group-item"><a href="">Professional Services</a></li>
                <li class="list-group-item"><a href="">Contact</a></li>
                <li class="list-group-item"><a href="">Support</a></li>
            </ul>

        </div>

        <div class="col">

            

        </div>
    </div>
</div>